<template>
  <div class="bag-record">
    <div class="search-box">
      <div class="left">
        <drop :dropList="dropList2" width="2.01rem" height="0.3rem" @dropItemClick="durecordtype" />
      </div>
    </div>
    <div class="title">
      <img src="../../assets/images/mobile/m-bagRecord-titlebg.png" alt="">
    </div>
    <div style="width: 3rem;margin: 0 auto;font-size: 0.09rem;">
      <record :tableRecordList="tableRecordList3" :tableData="tableData" renderSearch @pageChange="handleCurrentChange">
      </record>
    </div>

  </div>
</template>

<script>
import { dropList, dropList2, tableData, tableRecordList3 } from "./index.js";
import { Recharge_record } from "@/network/api.js";
import drop from "@/components/drop/index.vue";
import record from "../classicsBox/components/record.vue";

export default {
  name: "BagRecord",
  data() {
    return {
      dropList,
      dropList2,
      tableData,
      tableRecordList3,
      page_num: "1", //页码
      recordtype: null
    };
  },
  components: {
    drop,
    record,
  },
  created() {
    this.getrecord(this.recordtype)
  },
  methods: {
    // 获取到最新的钱包记录
    getrecord(num, page) {
      Recharge_record(num, page).then((res) => {
        console.log(res.data.data.data.length, '钱包记录');
        if (res.data.data.data.length > 0) {
          const data = res.data.data.data
          this.tableData.total = res.data.data.total
          this.tableData.pageSize = res.data.data.per_page
          this.tableData.list = data
        } else {
          this.tableData.total = res.data.data.total
          this.tableData.pageSize = res.data.data.per_page
          this.tableData.list = []
        }
      })
    },
    handleCurrentChange(val) {
      this.page_num = val;
      this.getrecord(this.recordtype, val.page)
    },
    // 根据类型获取钱包记录
    durecordtype(v) {
      console.log(v.id, '当前状态');
      this.recordtype = v.id
      this.getrecord(this.recordtype)
    }
  }
};
</script>
<style lang="scss" scoped>
.bag-record {
  width: 3.4rem;
  height: 8rem;
  margin: 0.1rem auto;
  background: url('../../assets/images/mobile/m-bagRecord-bg.png')no-repeat;
  background-size: 100% 100%;
  overflow: hidden;

  .search-box {
    .left {
      margin: 0.2rem 0 0 0;
    }
  }

  .title {
    width: 3.2rem;
    height: 0.2rem;
    margin: 0 auto;

    img {
      display: block;
      width: 100%;
      height: 100%;
    }
  }
}
</style>
