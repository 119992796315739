export const dropList = [
  {
    name: '近七日',
    id: 0,
  },
  {
    name: '近一月',
    id: 1
  }
]
export const dropList2 = [
  {
    name: '全部',
    id: null,
  },
  {
    name: '开箱',
    id: 1
  },
  {
    name: '盲盒对战',
    id: 2
  },
  {
    name: '幸运饰品',
    id: 3
  },
  {
    name: '装备回收',
    id: 4
  },
  {
    name: '充值',
    id: 5
  },
  {
    name: '首充奖励',
    id: 6
  },
  // 到时候再开放
  // {
  //   name: '被邀充值收益',
  //   id: 7
  // },
  // {
  //   name: '邀请注册奖励',
  //   id: 8
  // },
  // {
  //   name: '盲盒对战均分奖励',
  //   id: 9
  // },
  // {
  //   name: '盲盒对战失败安慰奖',
  //   id: 10
  // },
  // {
  //   name: '红包',
  //   id: 11
  // },
  // {
  //   name: '注册赠送',
  //   id: 23
  // },
]
export const tableRecordList3 = [
  {
    title: "交易时间",
    width: "53%",
    index: "created_at",
    center: "center",
  },
  {
    title: "交易类型",
    width: "45%",
    index: "change_type_alias",
    center: "center",
  },
  // {
  //   title: "订单号",
  //   width: "30%",
  //   index: "orderIo",
  //   center: "center",
  // },
  {
    title: "变动金额",
    width: "25%",
    index: "bean",
    center: "center",
  },
  {
    title: "变动余额",
    width: "25%",
    index: "final_bean",
    center: "center",
  }
];

export const tableData = {
  total: 15,
  list: [
    {}

  ]
};
